import React from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../data/projects.json";
import categoriesData from "../data/categories.json";
import { Container, Row, Col } from "react-bootstrap";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

const SubPrintsPage = () => {
  return (
    <Container id="category-container">
      <MenuBar activeHeader="Work" />
      <section id="work-page">
        <div className="categories-container">
          <Row className="align-items-center">
            <Col xs={12} md={12}>
              <div id="hero-wrap">
                <header className="subPageHeader">
                  <h1>Prints</h1>
                  <p>
                    Since 2015, I have been dedicated to creating a wide array
                    of printed materials that effectively communicate messages
                    and capture attention. Whether it’s crafting visually
                    compelling election materials, designing eye-catching event
                    invitations, or creating informative pamphlets and
                    brochures, my focus is on delivering impactful designs that
                    resonate with the intended audience. Explore my print
                    portfolio to see how I combine creativity and strategic
                    thinking to produce materials that make a lasting
                    impression.
                  </p>
                </header>
              </div>
            </Col>
            <Col
              xs={12}
              md={4}
              className="justify-content-center"
              style={{ display: "flex" }}
            >
              <Link
                to={`/work/prints/digital`}
                key="digital"
                style={{ textDecoration: "none" }}
              >
                <div className="categoryWrapper">
                  <h2 className="category-text">DIGITAL</h2>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              md={4}
              className="justify-content-center"
              style={{ display: "flex" }}
            >
              <Link
                to={`/work/prints/paraphernalia`}
                key="paraphernalia"
                style={{ textDecoration: "none" }}
              >
                <div className="categoryWrapper">
                  <h2 className="category-text">PARAPHERNALIA</h2>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              md={4}
              className="justify-content-center"
              style={{ display: "flex" }}
            >
              <Link
                to={`/work/prints/publications`}
                key="publications"
                style={{ textDecoration: "none" }}
              >
                <div className="categoryWrapper">
                  <h2 className="category-text">PUBLICATIONS</h2>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </Container>
  );
};

export default SubPrintsPage;
