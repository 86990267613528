import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import ProjectCategory from "./components/ProjectCategory";
import MenuBar from "./components/MenuBar";
import AboutPage from "./components/AboutPage";
import WorksPage from "./components/WorksPage";
import SubPrintsPage from "./components/SubPrintsPage";
import SubPhotographyPage from "./components/SubPhotographyPage";
import ContactPage from "./components/ContactPage";
import ProjectPage from "./components/ProjectPage";
import WritingPage from "./components/WritingPage";
import VideoPage from "./components/VideoPage";
import LoadingSpinner from "./components/LoadingSpinner";
import Footer from "./components/Footer";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import TrackVisibility from "react-on-screen";
import "animate.css";
import navIcon3 from "./assets/img/nav-icon3.svg";
import navIcon4 from "./assets/img/nav-icon4.svg";
import navIcon6 from "./assets/img/nav-icon6.svg";
import categoriesData from "./data/categories.json";

const ethAdd = "AT2VYXbe2Bb9oefpMqqLChk89q4WxFSr195Z6CDLpump";
const shortenedEthAdd = "AT2VYX...DLpump";
const ticker = "Let's Challenge the Established.";
const linkedIn = "https://www.linkedin.com/in/nadinebernardino/";
const telegram = "https://t.me/popsolmemes";
const dexscreener =
  "https://pump.fun/AT2VYXbe2Bb9oefpMqqLChk89q4WxFSr195Z6CDLpump";
const buylink = "https://raydium.io/swap/";
const footercontent = "2024 PopOnSolana. © All right reserved. test";

function importAll(r) {
  let images = [];
  r.keys().map((item, index) => {
    images.push(r(item));
  });

  console.log(images);
  return images;
}

const listOfImages = importAll(
  require.context("./assets/popjpegs", false, /\.(png|jpe?g|svg)$/)
);

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 520px)" });
  const selectedPage = "home";
  const delay = 20;

  useEffect(() => {
    let timeoutId;
    const checkReadyState = () => {
      if (document.readyState === "complete") {
        timeoutId = setTimeout(() => {
          setLoading(false);
        }, delay);
      } else {
        setTimeout(checkReadyState, 100); // check again after 100ms
      }
    };
    checkReadyState();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container id="main-container">
          <MenuBar activeHeader="Home" />
          <MainBanner />
        </Container>
      )}
    </div>
  );
}

function ProjectList() {
  return (
    <section id="project-list-section">
      <Row className="align-items-center">
        {categoriesData.map((category) => (
          <Col
            xs={4}
            key={category.categoryName}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Link
              to={`/category/${category.categoryName}`}
              key={category.categoryName}
              style={{ textDecoration: "none" }}
            >
              <div className="categoryWrapper">
                <h2 className="category-text">{category.categoryName}</h2>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </section>
  );
}

function MainBanner() {
  return (
    <section className="mainbanner" id="home">
      <video playsInline autoPlay loop muted>
        <source src="popjpegs/yellowboat.mp4" type="video/mp4" />
      </video>
      <Container>
        <Row>
          <Col xs={{ span: 12, order: 2 }} md={12}>
            <div id="hero-wrap">
              <header className="header">
                <h1>Nadine Bernardino</h1>
                <h3
                  style={{
                    fontFamily: "Helvetica Neue Black",
                  }}
                >
                  COMMUNICATIONS PRACTITIONER
                </h3>
                <h3>Visual | Strategic | Media & Public Relations</h3>
              </header>
            </div>
          </Col>
        </Row>
        <footer className="footer" style={{ paddingTop: "200px" }}>
          <h1 style={{ textTransform: "none" }}>
            Let's challenge the established!
          </h1>
          <div className="socialsHeader" style={{ paddingBottom: "100px" }}>
            <div className="social-icon">
              <a href={linkedIn}>
                <img src={navIcon3} alt="" />
              </a>
              <a href="">
                <img src={navIcon4} alt="" />
              </a>
            </div>
          </div>
          <div style={{ margin: "20px", textAlign: "center" }}>
            2024 NadineBernardino. © All right reserved.
          </div>
        </footer>
      </Container>
    </section>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<App />} />
        <Route path="/work/prints" element={<SubPrintsPage />} />
        <Route path="/work/photography" element={<SubPhotographyPage />} />
        <Route path="/work/:categoryName" element={<ProjectCategory />} />
        <Route
          path="/work/prints/:categoryName"
          element={<ProjectCategory subcategory="prints" />}
        />
        <Route
          path="/work/photography/:categoryName"
          element={<ProjectCategory subcategory="photography" />}
        />
        <Route
          path="/work/:categoryName/:projectName"
          element={<ProjectPage />}
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/work" element={<WorksPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/writing" element={<WritingPage />} />
        <Route path="/videos" element={<VideoPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
