import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Container, Row, Col } from "react-bootstrap";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

const ContactPage = () => {
  const [user_name, setName] = useState("");
  const [user_email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      user_name,
      user_email,
      message,
    };

    emailjs
      .sendForm("service_14jlfbf", "template_391tefu", form.current, {
        publicKey: "UYQIJ5gHy8TdC0CwH",
      })
      .then(
        (response) => {
          console.log(response);
          setSent(true);
        },
        (error) => {
          console.error("FAILED...", error);
        }
      );
  };

  return (
    <Container id="category-container">
      <MenuBar activeHeader="Contact" />
      <section id="work-page">
        <div className="categories-container">
          <Row className="align-items-center">
            <Col xs={12} md={12}>
              <div id="hero-wrap">
                <header className="subPageHeader">
                  <h1>Contact</h1>
                  <p>
                    Thank you for visiting my portfolio. If you’re interested in
                    collaborating on a project, have any questions, or just want
                    to connect, I’d love to hear from you. Whether you have a
                    creative idea in mind, need strategic communications
                    support, or want to discuss potential opportunities, please
                    don’t hesitate to reach out. Fill out the form below, and
                    I’ll get back to you as soon as possible. Let’s create
                    something impactful together!
                  </p>
                </header>
              </div>
            </Col>
            <Col xs={12}>
              <div id="contact-page-container" className="contact-page">
                <form ref={form} onSubmit={handleSubmit}>
                  <label>
                    Name:
                    <input
                      type="text"
                      name="user_name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </label>
                  <br />
                  <label>
                    Email:
                    <input
                      type="email"
                      name="user_email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </label>
                  <br />
                  <label>
                    Message:
                    <textarea
                      name="message"
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </label>
                  <br />
                  <button type="submit">Send Message</button>
                </form>
                {sent ? (
                  <p>
                    Thank you for sending us a message! We'll get back to you
                    soon.
                  </p>
                ) : (
                  <p>Please fill out the form and click send to contact us.</p>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </Container>
  );
};

export default ContactPage;
